import { useEffect, useState } from 'react';

const details = `
  name
  price
  _id
  slug {
    current
  }
	image {
		asset {
			url
			metadata {
				lqip
			}
		}
	}
`;

export function useLatestData() {
  // DVDs
  const [dvds, setDvds] = useState();
  // fetch data from graphql endpoint
  useEffect(function () {
    // when component loads, fetch data
    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query {
            StoreSettings(id: "music") {
              name
              dvd {
                ${details}
              }
            }
          }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setDvds(res.data.StoreSettings.dvd);
      })
      .catch((err) => {
        console.log('Arrgh!');
        console.log(err);
      });
  }, []);
  return {
    dvds,
  };
}
