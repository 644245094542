import React from 'react';
import { Link } from 'gatsby';
import { ItemsGrid, ItemStyles } from '../styles/Grids';
import formatMoney from '../utils/formatMoney';

export default function ItemGrid({ items }) {
  return (
    <ItemsGrid>
      {items.map((item) => (
        <ItemStyles key={item._id}>
          <Link to={`/dvds/${item.slug.current}`}>
            <img
              width="650"
              height="440"
              src={`${item.image.asset.url}?w=650&h=440&fit=crop`}
              alt={item.name}
              title={item.name}
              style={{
                background: `url(${item.image.asset.metadata.lqip})`,
                //   JS version, not background-size
                backgroundSize: 'cover',
                borderRadius: '4px',
              }}
            />
          </Link>
          <Link to={`/dvds/${item.slug.current}`}>
            <h3>{item.name}</h3>
          </Link>
          <p>
            <span>DVD</span> {formatMoney(item.price)}
          </p>
          <div className="mbl-btn">
            <Link to={`/dvds/${item.slug.current}`}>
              <button className="btn">See Details</button>
            </Link>
          </div>
        </ItemStyles>
      ))}
    </ItemsGrid>
  );
}
