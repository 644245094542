import React from 'react';
import styled from 'styled-components';
import bg from '../assets/images/sgm-conducting-bg.jpg';

const HeaderStyles = styled.header`
  overflow: hidden;
  background-color: var(--white);
  .hero-background {
    height: 100vh;
    background-size: cover;
    background-color: var(--black);
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: center center;
    /* box-shadow: 0 12px 30px 10px rgba(0, 0, 0, 0.15); */
    @media screen and (max-width: 1300px) {
      background-position: 80% center;
    }
    @media screen and (max-width: 768px) {
      height: 500px;
      background-color: var(--white);
      background-size: cover;
      background-attachment: unset;
      background-position: 50% top;
    }
    @media screen and (max-width: 650px) {
      /* background-size: 170%; */
      background-size: cover;
      background-position: 100% 0%;
      /* height: 230px; */
      margin-bottom: 20px;
    }
  }
  .site-heading {
    width: 100%;
    padding-top: 15%;
    @media screen and (max-width: 1200px) {
      padding-top: 20%;
    }
    &.desktop {
      display: block;
      /* max-width: 349px; */
      @media screen and (max-width: 880px) {
        h1 {
          font-size: 125px;
        }
      }
      @media screen and (max-width: 768px) {
        h1 {
          font-size: 100px;
        }
      }
      span {
        font-family: LeagueGothic;
        font-size: 40px;
        text-transform: uppercase;
        color: #fff;
        display: inline-block;
        line-height: 1.2;
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        border-bottom: 2px solid var(--white);
        @media screen and (max-width: 880px) {
          font-size: 35px;
        }
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }
      @media screen and (max-width: 650px) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      margin: 0 auto;
      padding-top: 10px;
      /* margin-bottom: 50px; */
      @media screen and (max-width: 650px) {
        display: block;
        text-align: center;
        span {
          font-family: LeagueGothic;
          display: block;
          color: var(--black);
          font-size: 28px;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-align: center;
        }
      }
      h1 {
        font-size: 67px;
        line-height: 80px;
        padding-bottom: 0;
        text-align: center;
        color: var(--black);
        display: inline-block;
        border-top: 2px solid var(--black);
      }
    }
    h1 {
      padding-bottom: 10px;
      margin-bottom: 12px;
    }
  }
`;

export default function Header() {
  return (
    <HeaderStyles id="welcome">
      <div className="hero-background">
        <div className="wrap">
          <div className="site-heading desktop">
            <span>The Legacy of Composer</span>
            <h1>
              Stephen <br />
              Gerber
            </h1>
          </div>
        </div>
      </div>
      <div className="site-heading mobile">
        <div className="wrap">
          <span>The Legacy of Composer</span>
          <h1>Stephen Gerber</h1>
        </div>
      </div>
    </HeaderStyles>
  );
}
