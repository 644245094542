import styled from 'styled-components';

export const ItemsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0px;
  div {
    width: 30%;
    transition: all 0.24s ease-in-out;
  }
  @media screen and (max-width: 650px) {
    display: block;
    div {
      width: 100%;
    }
  }
`;

// Single Grid Item (home page)
export const ItemStyles = styled.div`
  text-align: left;
  position: relative;
  padding: 0;
  margin-bottom: 5px;
  @media screen and (max-width: 650px) {
    padding: 60px 0 60px;
    border-bottom: 1px solid var(--grey);
    &:first-of-type {
      padding-top: 20px;
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
  /* @keyframes shine {
    from {
      background-position: 200%;
    }
    to {
      background-position: -40px;
    }
  } */
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    font-size: 0;
    margin-bottom: 18px;
    display: block;
    /* &.loading {
      --shine: white;
      --background: var(--grey);
      background-image: linear-gradient(
        90deg,
        var(--background) 0px,
        var(--shine) 40px,
        var(--background) 80px
      );
      background-size: 500px;
      animation: shine 1s infinite linear;
    } */
  }
  h3 {
    color: var(--gold);
    font-size: 2rem;
    font-weight: 500;
    font-size: clamp(20px, 8vw, 34px);
    letter-spacing: 0.2px;
    line-height: 1.1;
    margin: 0 0 10px;
    text-transform: none;
    transition: all 0.34s ease-in-out;
    &:hover {
      color: var(--green);
    }
    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
    @media screen and (max-width: 650px) {
      font-size: clamp(20px, 8vw, 31px);
    }
  }
  p {
    width: 100%;
    margin: 0;
    color: var(--red);
    line-height: 1.2;
    font-size: 2rem;
    font-size: clamp(12px, 5vw, 22px);
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 650px) {
      font-size: clamp(12px, 5vw, 22px);
    }
    span {
      font-family: LeagueGothic;
      font-size: inherit;
      font-weight: 400;
      display: inline-block;
      padding: 4px 12px 2px;
      margin-right: 4px;
      line-height: inherit;
      border-radius: 4px;
      text-transform: uppercase;
      color: var(--black);
      background-color: var(--grey);
    }
  }
  .mbl-btn {
    display: none;
    button {
      background-color: var(--gold);
      color: #fff;
    }
    @media screen and (max-width: 650px) {
      display: block;
      margin: 30px 0 0;
    }
  }
`;

// Button Styles
export const ButtonsGrid = styled.div`
  .btn-frame {
    width: 30%;
    transition: all 0.24s ease-in-out;
  }
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0px;
`;
