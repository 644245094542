import React from 'react';
import styled from 'styled-components';

const AboutStyles = styled.div`
  @mixin break($p, $b: 'max-width') {
    @media screen and ($b: $p + px) {
      @content;
    }
  }
  #about {
    // height: 100vh;
    position: relative;
    display: block;
    padding: 40px 0;
    background-color: var(--white);
    h2 {
      text-align: center;
      @media screen and (max-width: 650px) {
        text-align: left;
        font-size: 38px;
      }
    }
    p {
      text-align: left;
      max-width: 900px;
      margin: 0 auto 25px;
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 650px) {
      padding: 1px 0 40px;
    }
  }
`;

export default function About() {
  return (
    <AboutStyles>
      <section id="about">
        <div className="wrap">
          <h2>About</h2>
          <p>
            Stephen E. Gerber (1948 - 2013) was an American composer, lyricist,
            conductor, and pianist. New York debut: conducting his Concerto for
            Timpani &amp; Orchestra at Lincoln Center. Orchestral compositions
            performed by, among others, New World Symphony Orchestra, Cincinnati
            Symphony, Milwaukee Symphony, San Antonio Symphony, Porte Alegre
            (Brazil) Symphony, Charlotte Symphony, Middletown Symphony, Illinois
            Philharmonic, New England Conservatory Orchestra, Lexington
            Symphony, Claflin Hill Symphony. Librettist: Suite Dreams!,
            premiered by Boston Symphony Orchestra. What If? (Don’t Give Up for
            voice/orchestra) performed with Wellesley Symphony and Boston Civic
            Symphony with his wife, Nancy. Stephen’s catalogue includes holiday
            orchestra arrangements, choral and chamber music, sacred
            arrangements (recorded by Douglas Yeo, former Boston Symphony
            Orchestra bass trombonist) and concerti. Music Director: Grace
            Chapel in Lexington, Westgate Church in Weston, Union Church of
            Stow, The Shirley Chorale, USO tour in Europe, with Taft
            Broadcasting he worked in New York, Canada and Mexico as staff MD
            and orchestrator. He wrote music and lyrics for national radio and
            TV commercials and once conducted 250 tuba players at the
            Tubajubalee festival in Cincinnati. His career also included working
            on staff for Galaxy Music NYC, ECS Music Publishers Boston and
            officer on the board of Music Publishers Association of the US.
            Accepted into the prestigious BMI Musical Theatre Writers and ASCAP
            Musical Theatre Workshop in NYC, he worked with Ed Kleban, Stephen
            Sondheim, Martin Charnin, Stephen Schwartz. Stephen and Nancy’s
            production company Acts from the Heart produced dozens of musicals
            around the country, partnering with Home Improvement Ministries and
            the School of Creative Arts.
          </p>
          <p>
            We invite you to continue checking here in the coming months as we
            will soon offer sheet music for purchase as well as full orchestra rentals not currently available through
            commercial publication.
          </p>
        </div>
      </section>
    </AboutStyles>
  );
}
