import React from 'react';
import styled from 'styled-components';
import { ButtonsGrid } from '../styles/Grids';
import Header from '../components/Header';
import About from '../components/About';
import Footer from '../components/Footer';
import { useLatestData } from '../utils/useLatestData';
import ItemGrid from '../components/ItemGrid';
import SEO from '../components/SEO';
import CheckoutSOCE99 from '../components/CheckoutSOCE-99';
import CheckoutSOCE05 from '../components/CheckoutSOCE-05';
import CheckoutSOCE17 from '../components/CheckoutSOCE-17';

const ViewportStyles = styled.div`
  div.viewport {
    width: calc(100% - 37px);
    position: absolute;
    right: 0;
    z-index: 99;
    overflow: hidden;
    @include break(580) {
      width: calc(100% - 30px);
    }
  }
`;

const FeaturedStyles = styled.div`
  background-color: var(--white);
  #featured {
    position: relative;
    padding: 40px 0;
    margin-bottom: 20px;
    background-color: var(--white);

    small {
      display: block;
      text-align: right;
      margin: 60px 0 0;
      @media screen and (max-width: 650px) {
        margin: 0;
        text-align: left;
      }
    }
    .wrap {
      border-top: 1px solid #ebebeb;
    }
    h2 {
      text-align: center;
      padding-top: 30px;
      @media screen and (max-width: 650px) {
        text-align: left;
        font-size: 38px;
      }
    }
    @media screen and (max-width: 651px) {
      .btn-frame {
        display: none;
      }
    }
  }
`;

export default function HomePage({ title }) {
  const { dvds } = useLatestData();
  return (
    <>
      <SEO title="SGM" />
      <ViewportStyles>
        {/* viewport */}
        <div className="">
          <Header />
          <About />

          <FeaturedStyles>
            <section id="featured">
              <div className="wrap">
                <h2>Featured Performances</h2>
                <p className="intro">
                  The original musical Suddenly On Christmas Eve, for which
                  Stephen wrote the music, lyrics, and libretto, has become a musical Christmas favorite in the New
                  England area.
                </p>
                <p className="intro">
                  We are accepting pre-orders for live performance
                  recordings that will be available on DVD. We have curated the
                  top performances from a collection of different years, casts,
                  and venues. We hope you will enjoy the present offering.
                </p>
                <p className="intro last">
                  Suddenly on Christmas Eve is a heart-warming Broadway-style
                  experience for the whole family, full of powerful and
                  uplifting music, exciting and energetic dance numbers, lots of
                  fun, and an inspirational Christmas message. Based on a true
                  story, this original family musical about hope and forgiveness
                  centers around a family whose lives are forever marked by the
                  Great Depression.
                </p>
                <div>
                  <FeaturedProducts dvds={dvds} />
                  <ButtonsGrid>
                    <CheckoutSOCE17 />
                    <CheckoutSOCE05 />
                    <CheckoutSOCE99 />
                  </ButtonsGrid>
                </div>
                <small>
                  <em>Photography by John Harmon</em>
                </small>
              </div>
            </section>
          </FeaturedStyles>

          <Footer />
        </div>
      </ViewportStyles>
    </>
  );
}

function FeaturedProducts({ dvds }) {
  return <div>{dvds?.length && <ItemGrid items={dvds} />}</div>;
}
